<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-8">
        <h1>Supplier Categories</h1>
      </v-col>
      <v-col class="col-4 text-right">
        <router-link to="/purchasing/add-supplier-category">
          <v-btn>Add New Category</v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(category, index) in supplierCategories" :key="`c_${index}`">
              <td>{{ category.category }}</td>
              <td class="text-right">
                <router-link :to="`/purchasing/edit-supplier-category/${category.id}`">
                  <v-btn x-small>Edit</v-btn>
                </router-link>
                &nbsp;
                <v-btn
                  @click="archive(index)"
                  x-small>Archive</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'PurchasingSupplierCategories',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      supplierCategories: [],
    };
  },
  methods: {
    archive(index) {
      const category = this.supplierCategories[index];
      axios.get(`/supplierCategories/archive/${category.id}.json?token=${this.token}`)
        .then(() => {
          this.supplierCategories.splice(index, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategories() {
      axios.get(`/supplierCategories/getAll.json?token=${this.token}`)
        .then((response) => {
          this.supplierCategories = response.data.categories;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
